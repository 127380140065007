@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import 'react-datepicker/dist/react-datepicker';
html,
body {
  margin: 0;
 
}
.MuiCardActionArea-root {
  font-family: 'Roboto';
}

.legal_____content>p {
  color: #676767;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}
.filtered-img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.filter-green {
  filter: invert(31%) sepia(96%) saturate(375%) hue-rotate(120deg) brightness(93%) contrast(96%);
}

.filter-red {
  filter: invert(23%) sepia(90%) saturate(6259%) hue-rotate(343deg) brightness(90%) contrast(105%);
}

 .react-datepicker__triangle {
  left:-115px !important
}

#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}
#react-select-1-placeholder{
  margin-left: 18px;
}
#react-select-2-placeholder{
  margin-left: 18px;
}
#react-select-3-placeholder{
  margin-left: 18px;
}
#react-select-4-placeholder{
  margin-left: 18px;
}
#react-select-5-placeholder{
  margin-left: 18px;
}
#react-select-6-placeholder{
  margin-left: 18px;
}
#react-select-7-placeholder{
  margin-left: 18px;
}
#react-select-8-placeholder{
  margin-left: 18px;
}
#react-select-9-placeholder{
  margin-left: 18px;
}
#react-select-10-placeholder{
  margin-left: 18px;
}
#react-select-11-placeholder{
  margin-left: 18px;
}
#react-select-12-placeholder{
  margin-left: 18px;
}
#react-select-13-placeholder{
  margin-left: 18px;
}

// .css-1fdsijx-ValueContainer{
//   padding: 0px !important;
// }